import * as requestFromServer from '../common/cruds'
import {BookingSlices} from './slices'
import {handleNotificationWithToastify} from '../../../app/services/Functional'
import {callTypes, catchError, endCall, startCall} from '../common/slices'

const actions = BookingSlices.actions
const API_URL = `${process.env.REACT_APP_API_BASE_URL}bookings/`

export const getBookingById = (id: string, type: string) => (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .getRequest(`${API_URL}${id}/${type}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            dispatch(actions.booking(response.data.data))
        })
        .catch(error => {
            error.clientMessage = 'Can\'t find booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}

export const getBookingItineraryDetailsById = (id: string) => (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .getRequest(`${API_URL}itinerary-details/${id}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            dispatch(actions.itineraryDetails(response.data.data))
        })
        .catch(error => {
            error.clientMessage = 'Can\'t find booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}

export const getInvoiceNoteDetailsByBookingId = (id: string) => (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .getRequest(`${API_URL}invoice-note/${id}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            dispatch(actions.invoiceNote(response.data.data))
        })
        .catch(error => {
            error.clientMessage = 'Can\'t find booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}

export const getCreditNoteDetailsByBookingId = (id: string) => (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .getRequest(`${API_URL}credit-note/${id}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            dispatch(actions.creditNote(response.data.data))
        })
        .catch(error => {
            error.clientMessage = 'Can\'t find booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}

export const getHotelByBookingId = (booking_identifier: string, hotel_id: number) => (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .getRequest(`${API_URL}hotel-details/${booking_identifier}/${hotel_id}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            dispatch(actions.hotels(response.data.data))
        })
        .catch(error => {
            error.clientMessage = 'Can\'t find booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}
export const getSightSeeingByBookingId = (booking_identifier: string, sight_seeing_id: number) => (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .getRequest(`${API_URL}sight-seeing-details/${booking_identifier}/${sight_seeing_id}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            dispatch(actions.sightSeeings(response.data.data))
        })
        .catch(error => {
            error.clientMessage = 'Can\'t find booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}
export const getTransferByBookingId = (booking_identifier: string, transfer_id: number) => (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .getRequest(`${API_URL}transfer-details/${booking_identifier}/${transfer_id}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            dispatch(actions.transfers(response.data.data))
        })
        .catch(error => {
            error.clientMessage = 'Can\'t find booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}
export const getPackageByBookingId = (booking_identifier: string, package_id: number) => (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .getRequest(`${API_URL}package-details/${booking_identifier}/${package_id}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            dispatch(actions.packages(response.data.data))
        })
        .catch(error => {
            error.clientMessage = 'Can\'t find booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}
export const getCustomServiceByBookingId = (booking_identifier: string, custom_service_id: number) => (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .getRequest(`${API_URL}custom-service-details/${booking_identifier}/${custom_service_id}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            dispatch(actions.customServices(response.data.data))
        })
        .catch(error => {
            error.clientMessage = 'Can\'t find booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}
export const getCustomHotelServiceByBookingId = (booking_identifier: string, custom_hotel_id: number) => (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .getRequest(`${API_URL}custom-hotel-details/${booking_identifier}/${custom_hotel_id}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            dispatch(actions.customHotelServices(response.data.data))
        })
        .catch(error => {
            error.clientMessage = 'Can\'t find booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}
export const confirmHotelByBookingId = (payload: {
    hotel_id: number,
    confirmation_no: string
}) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.postRequest(`${API_URL}hotel-confirm`, payload)
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
}
export const confirmCustomHotelByBookingId = (payload: {
    hotel_id: number,
    confirmation_no: string
}) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.postRequest(`${API_URL}custom-hotel-confirm`, payload)
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
}
export const confirmSightSeeingByBookingId = (payload: {
    sight_seeing_id: number,
    confirmation_no: string
}) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.postRequest(`${API_URL}sight-seeing-confirm`, payload)
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
}
export const confirmTransferByBookingId = (payload: {
    transfer_id: number,
    confirmation_no: string
}) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.postRequest(`${API_URL}transfer-confirm`, payload)
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
}
export const confirmPackageByBookingId = (payload: {
    package_id: number,
    confirmation_no: string
}) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.postRequest(`${API_URL}package-confirm`, payload)
    dispatch(endCall({callType: callTypes.action}))
    handleNotificationWithToastify(response.data)
}
export const confirmCustomServiceByBookingId = (payload: {
    custom_service_id: number,
    confirmation_no: string
}) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.postRequest(`${API_URL}custom-service-confirm`, payload)
    dispatch(endCall({callType: callTypes.action}))
    handleNotificationWithToastify(response.data)
}
export const updateConfirmation = (payload: {
    service: string,
    id: number,
    confirmation_no: string
}) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.postRequest(`${API_URL}update-confirmation`, payload)
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
}
export const cancelHotelByBookingId = (payload: {
    hotel_id: number,
    cancellation_reason: string
    cancellation_amount: number
}) => async (dispatch: any) => {
    try {
        dispatch(startCall({callType: callTypes.action}))
        const response = await requestFromServer.postRequest(`${API_URL}hotel-cancel`, payload)
        dispatch(endCall({callType: callTypes.action}))
        handleNotificationWithToastify(response.data)
    } catch (e: any) {
        dispatch(catchError({error: e.message, callType: callTypes.action}))
    }
}
export const cancelCustomHotelByBookingId = (payload: {
    hotel_id: number,
    cancellation_reason: string
    cancellation_amount: number
}, booking_identifier: string) => async (dispatch: any) => {
    try {
        dispatch(startCall({callType: callTypes.action}))
        const response = await requestFromServer.postRequest(`${API_URL}custom-hotel-cancel`, payload)
        await dispatch(getCustomHotelServiceByBookingId(booking_identifier, payload.hotel_id))
        dispatch(endCall({callType: callTypes.action}))
        handleNotificationWithToastify(response.data)
    } catch (e: any) {
        dispatch(catchError({error: e.message, callType: callTypes.action}))
    }
}
export const cancelSightSeeingByBookingId = (payload: {
    sight_seeing_id: number,
    cancellation_reason: string
    cancellation_amount: number
}) => async (dispatch: any) => {
    try {
        dispatch(startCall({callType: callTypes.action}))
        const response = await requestFromServer.postRequest(`${API_URL}sight-seeing-cancel`, payload)
        dispatch(endCall({callType: callTypes.action}))
        handleNotificationWithToastify(response.data)
    } catch (e: any) {
        dispatch(catchError({error: e.message, callType: callTypes.action}))
    }
}
export const cancelTransferByBookingId = (payload: {
    transfer_id: number,
    cancellation_reason: string
    cancellation_amount: number
}) => async (dispatch: any) => {
    try {
        dispatch(startCall({callType: callTypes.action}))
        const response = await requestFromServer.postRequest(`${API_URL}transfer-cancel`, payload)
        dispatch(endCall({callType: callTypes.action}))
        handleNotificationWithToastify(response.data)
    } catch (e: any) {
        dispatch(catchError({error: e.message, callType: callTypes.action}))
    }
}
export const cancelPackageByBookingId = (payload: {
    package_id: number,
    cancellation_reason: string
    cancellation_amount: number
}) => async (dispatch: any) => {
    try {
        dispatch(startCall({callType: callTypes.action}))
        const response = await requestFromServer.postRequest(`${API_URL}package-cancel`, payload)
        dispatch(endCall({callType: callTypes.action}))
        handleNotificationWithToastify(response.data)
    } catch (e: any) {
        dispatch(catchError({error: e.message, callType: callTypes.action}))
    }
}
export const cancelCustomServiceByBookingId = (payload: {
    custom_service_id: number,
    cancellation_reason: string
    cancellation_amount: number
}) => async (dispatch: any) => {
    try {
        dispatch(startCall({callType: callTypes.action}))
        const response = await requestFromServer.postRequest(`${API_URL}custom-service-cancel`, payload)
        dispatch(endCall({callType: callTypes.action}))
        handleNotificationWithToastify(response.data)
    } catch (e: any) {
        dispatch(catchError({error: e.message, callType: callTypes.action}))
    }
}

export const voucherHotelByBookingId = (hotel_id: number, booking_identifier: string) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}hotel-voucher`, {hotel_id})
    dispatch(getHotelByBookingId(booking_identifier, hotel_id))
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response.data
}
export const voucherCustomHotelByBookingId = (hotel_id: number, booking_identifier: string, custom_hotel_id: number) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}custom-hotel-voucher`, {hotel_id})
    dispatch(getCustomHotelServiceByBookingId(booking_identifier, custom_hotel_id))
    await handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response.data
}
export const voucherSightSeeingByBookingId = (sight_seeing_id: number) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}sight-seeing-voucher`, {sight_seeing_id})
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response.data
}
export const voucherTransferByBookingId = (transfer_id: number) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}transfer-voucher`, {transfer_id})
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response.data
}
export const voucherPackageByBookingId = (package_id: number) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}package-voucher`, {package_id})
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response.data
}

export const voucherCustomServiceByBookingId = (custom_service_id: number) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}custom-service-voucher`, {custom_service_id})
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response.data
}

export const voucherAllByBooking = (identifier: string) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}voucher-all`, {identifier})
    await dispatch(getBookingById(identifier, 'show'))
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response.data
}
export const cancelAllServiceByBooking = (identifier: string) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}cancel-all-services`, {identifier})
    await dispatch(getBookingById(identifier, 'show'))
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response.data
}

export const updateLeadPassengerDetails = (payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-lead-passenger-details`, payload)
    await dispatch(getBookingById(payload.booking_identifier, 'show'))
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}
export const updateRoomPassengerDetails = (payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-room-passenger-details`, payload)
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}
export const updateCustomRoomPassengerDetails = (payload: any, identifier: string, hotel_id: number) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-custom-room-passenger-details`, payload)
    dispatch(getCustomHotelServiceByBookingId(identifier, hotel_id))
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}
export const updateSightSeeingPassengerDetails = (payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-sight-seeing-passenger-details`, payload)
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}
export const updateTransferPassengerDetails = (payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-transfer-passenger-details`, payload)
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}
export const updatePackagePassengerDetails = (payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-package-passenger-details`, payload)
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}
export const updateCustomServicePassengerDetails = (payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-custom-service-passenger-details`, payload)
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}
export const createBooking = (payload: any) => (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .postRequest(`${API_URL}create`, payload)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            handleNotificationWithToastify(response.data)
            return response.data
        })
        .catch(error => {
            error.clientMessage = 'Can\'t create booking'
            dispatch(catchError({error, callType: callTypes.action}))
            return error
        })
}

export const updateBooking = (payload: any) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .patchRequest(`${API_URL}${payload.get('id')}`, payload, {
            'Content-Type': 'multipart/form-data',
        })
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            handleNotificationWithToastify(response.data)
            return response.data
        })
        .catch(error => {
            error.clientMessage = 'Can\'t update booking'
            dispatch(catchError({error, callType: callTypes.action}))
            return error
        })
}

export const deleteBooking = (id: number) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer
        .deleteRequest(`${API_URL}${id}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            handleNotificationWithToastify(response.data)
        })
        .catch(error => {
            error.clientMessage = 'Can\'t delete booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}

export const deleteBookingServiceById = (booking_id: string, payload: {
    id: number,
    service: string
}) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.postRequest(`${API_URL}delete-booking-service`, payload)
    dispatch(getBookingById(booking_id, 'show'))
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
}

export const addServiceToExistingBooking = (service: string, booking_id: string, payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.postRequest(`${API_URL}add-booking-service/${booking_id}/${service}`, payload)
    dispatch(getBookingById(booking_id, 'show'))
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response.data
}
export const confirmQuotation = (booking_id: string) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.postRequest(`${API_URL}confirm-quotation/${booking_id}`)
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
}

export const validateQuotation = (booking_id: string) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.postRequest(`${API_URL}validate-quotation/${booking_id}`)
    // handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response.data;
}


export const updateRoomQuotationPriceDetails = (booking_identifier: string, hotel_id: number, payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-room-price-details`, payload)
    if (response.data.success) {
        dispatch(getHotelByBookingId(booking_identifier, hotel_id))
    }
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}


export const updateSightSeeingPriceDetails = (booking_identifier: string, sight_seeing_id: number, payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-sight-seeing-price-details`, payload)
    if (response.data.success) {
        dispatch(getSightSeeingByBookingId(booking_identifier, sight_seeing_id))
    }
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}
export const updateTransferPriceDetails = (booking_identifier: string, transfer_id: number, payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-transfer-price-details`, payload)
    if (response.data.success) {
        dispatch(getTransferByBookingId(booking_identifier, transfer_id))
    }
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}

export const updatePackagePriceDetails = (booking_identifier: string, package_id: number, payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-package-price-details`, payload)
    if (response.data.success) {
        dispatch(getPackageByBookingId(booking_identifier, package_id))
    }
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}

export const updateCustomServicePriceDetails = (booking_identifier: string, custom_service_id: number, payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-custom-service-price-details`, payload)
    if (response.data.success) {
        dispatch(getCustomServiceByBookingId(booking_identifier, custom_service_id))
    }
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}
export const updateBookingPriceDetails = (booking_identifier: string, payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer
        .postRequest(`${API_URL}update-price-details`, payload)
    if (response.data.success) {
        dispatch(getBookingById(booking_identifier, 'show'))
    }
    handleNotificationWithToastify(response.data)
    dispatch(endCall({callType: callTypes.action}))
    return response
}
export const addMarkupOnBooking = (booking_identifier: string, payload: any) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    try {
        const response = await requestFromServer
            .postRequest(`${API_URL}add-markup-on-booking/${booking_identifier}`, payload)
        if (response.data.success) {
            dispatch(getBookingById(booking_identifier, 'show'))
        }
        handleNotificationWithToastify(response.data)
        dispatch(endCall({callType: callTypes.action}))
        return response
    } catch (error) {
        dispatch(endCall({callType: callTypes.action}))
    }
}

export const getPackageHotelDetails = (booking_package_id: string) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    return requestFromServer.getRequest(`${API_URL}package-hotel-details/${booking_package_id}`)
        .then(response => {
            dispatch(endCall({callType: callTypes.action}))
            dispatch(actions.packageHotelDetails(response.data.data))
        })
        .catch(error => {
            error.clientMessage = 'Can\'t delete booking'
            dispatch(catchError({error, callType: callTypes.action}))
        })
}

export const updatePackageHotel = (payload: any, booking_identifier: string) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.patchRequest(`${API_URL}update-package-hotel`, payload)
    await dispatch(getPackageByBookingId(booking_identifier, payload.package_id))
    dispatch(endCall({callType: callTypes.action}))
    handleNotificationWithToastify(response.data)
}

export const updatePackageItinerary = (payload: any, booking_identifier: string, package_id: number) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.patchRequest(`${API_URL}update-package-itinerary`, payload)
    await dispatch(getPackageByBookingId(booking_identifier, package_id))
    dispatch(endCall({callType: callTypes.action}))
    handleNotificationWithToastify(response.data)
}
export const deletePackageItinerary = (id: number, booking_identifier: string, package_id: number) => async (dispatch: any) => {
    dispatch(startCall({callType: callTypes.action}))
    const response = await requestFromServer.deleteRequest(`${API_URL}delete-package-itinerary/${id}`)
    await dispatch(getPackageByBookingId(booking_identifier, package_id))
    dispatch(endCall({callType: callTypes.action}))
    handleNotificationWithToastify(response.data)
}

export const saveAdvanceReceivedData = (payload: { booking_identifier: string; advance_received: number }) => async (dispatch: any) => {
    try {
        dispatch(startCall({callType: callTypes.action}))
        const response = await requestFromServer
            .postRequest(`${API_URL}add-advance-received`, payload)
        if (response.data.success) {
            dispatch(getBookingById(payload.booking_identifier, 'show'))
        }
        handleNotificationWithToastify(response.data)
        dispatch(endCall({callType: callTypes.action}))
        return response.data
    }catch (error) {
        dispatch(catchError({error, callType: callTypes.action}))
    }
}