import {createSlice} from '@reduxjs/toolkit'

const initialSightSeeingState = {
    totalCount: 0,
    sight_seeings: null,
    sight_seeing: null,
    sight_seeing_show: null,
    documentsDropdown: null,
    stopSale: {},
    bookingSearchSightSeeing: [],
    bookingSearchSightSeeingCount: 0
}

export const SightSeeingSlices = createSlice({
    name: 'sight_seeings',
    initialState: initialSightSeeingState,
    reducers: {
        sight_seeing: (state, action) => {
            state.sight_seeing = action.payload.sight_seeing
        },
        sight_seeing_show: (state, action) => {
            state.sight_seeing_show = action.payload.sight_seeing
        },
        sight_seeings: (state, action) => {
            state.sight_seeings = action.payload.sight_seeings
            state.totalCount = action.payload.total_count
        },
        stopSale: (state, action) => {
            state.stopSale = action.payload.data
        },
        documentList: (state, action) => {
            state.documentsDropdown = action.payload.documents;
        },
        sightSeeingsByBookingSearch: (state, action) => {
            state.bookingSearchSightSeeing = action.payload.data.sightSeeings
            state.bookingSearchSightSeeingCount = action.payload.data.total_count
        },
    },
})

export const {sightSeeingsByBookingSearch} = SightSeeingSlices.actions